import { createSlice, nanoid } from '@reduxjs/toolkit'
import { selectEnemiesByLevel, receiveAttack } from '../enemies/enemiesSlice';
import { selectOres, spendOres, addOres } from '../player/playerSlice';

const LASER_DEFENSE_BASE_COST = 5;
const ORE_GENERATOR_BASE_COST = 20;

export const buildingsSlice = createSlice({
    name: 'buildings',
    initialState: {
        byId: {},
        byLevel: {},
        clearedLevels: {},
        nextAvailableLevel: undefined,
        costs: {
            laserDefense: LASER_DEFENSE_BASE_COST,
            oreGenerator: ORE_GENERATOR_BASE_COST
        }
    },
    reducers: {
        clearLevel: (state, action) => {
            const level = action.payload;
            state.clearedLevels[level+1] = true;
            if(state.nextAvailableLevel == null) {
                state.nextAvailableLevel = level+1;
            }
        },
        doBuild: {
          reducer: (state, action) => {
            const {id, type, level} = action.payload;
            let building;
            if(type === 'laserDefense') {
                building = {
                    id,
                    type,
                    strength: 10,
                    cooldown: 3000,
                    next: 0,
                    level
                }
            } else if(type === 'oreGenerator') {
                building = {
                    id,
                    type,
                    strength: 1,
                    cooldown: 5000,
                    next: 0,
                    level
                }
            }

            state.byId[building.id] = building;
            state.byLevel[building.level] = building;

            const nextLevel = state.nextAvailableLevel + 1;
            state.nextAvailableLevel = state.clearedLevels[nextLevel] ? nextLevel : null;
          },
          prepare: ({type, level}) => {
            return {
                payload: {
                    id: nanoid(),
                    type,
                    level
                }
            }
          }
        },
        reset: (state, action) => {
            state.byId = {};
            state.byLevel = {};
            state.clearedLevels = {};
            state.nextAvailableLevel = undefined;
            state.costs.laserDefense = LASER_DEFENSE_BASE_COST;
            state.costs.oreGenerator = ORE_GENERATOR_BASE_COST;
        },
        charge: (state, action) => {
            const {dt, id} = action.payload;
            state.byId[id].next = Math.max(state.byId[id].next - dt, 0);
        },
        use: (state, action) => {
            const {id} = action.payload;
            state.byId[id].next = state.byId[id].cooldown;
        },
        increaseCost: (state, action) => {
            const {type, cost} = action.payload;
            state.costs[type] = cost;
        }
    }
})

// Action creators are generated for each case reducer function
export const { clearLevel, doBuild, reset, charge, use, increaseCost } = buildingsSlice.actions

export const getCost = type => (state) => state.buildings.costs[type];
export const canBuild = type => (state) => {
    if(state.buildings.nextAvailableLevel == null) return false;
    const cost = getCost(type)(state);
    const ores = selectOres(state);
    return ores >= cost;
}
export const getByLevel = (state, level) => state.buildings.byLevel[level];
export const selectById = (state, id) => state.buildings.byId[id];

export function build(type) {
    return (dispatch, getState) => {
        const state = getState();
        const level = state.buildings.nextAvailableLevel;
        if(canBuild(type)(state)) {
            const cost = getCost(type)(state);
            dispatch(spendOres({amount: cost}));
            dispatch(doBuild({type, level}));
            dispatch(increaseCost({type, cost: Math.ceil(cost * 1.5)}));
        }        
    }
}

export function run(dt, id) {
    return (dispatch, getState) => {
        dispatch(charge({dt, id}));
        const building = selectById(getState(), id);
        if(building.next <= 0) {
            
          if(building.type === 'laserDefense') {
              // hits all enemies on level
              const enemies = selectEnemiesByLevel(getState(), building.level);
              if(enemies?.length > 0) {
                dispatch(use({id}));
                for(const enemy of enemies) {
                    dispatch(receiveAttack({id: enemy.id, amount: building.strength}));
                }
              }
          } else if(building.type === 'oreGenerator') {
              // woohoo free ore
              dispatch(use({id}));
              dispatch(addOres({amount: building.strength}));
          }
        }
    }
}

export default buildingsSlice.reducer