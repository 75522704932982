import { createSlice } from '@reduxjs/toolkit'

let autoInterveral;

export const materialSlice = createSlice({
  name: 'material',
  initialState: {
        value: 0,
        autoMultiplier: 0
  },
  reducers: {
    increaseByAmount: (state, action) => {
        state.value += action.payload
    },
    decreaseByAmount: (state, action) => {
        state.value -= action.payload
    },
    increaseAuto: (state, action) => {
        state.auto += action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { increaseByAmount, decreaseByAmount, increaseAuto } = materialSlice.actions

export const autoStart = (autoCount, speed) => dispatch => {    
  
    autoInterveral = setInterval(() => {        
        console.log('auto: ', speed, autoCount);
        dispatch(increaseByAmount(autoCount));
    }, speed);  
    
};

export const autoStop = () => {
    clearInterval(autoInterveral);
}

export default materialSlice.reducer