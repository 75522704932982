import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import Phaser, { Scene} from 'phaser';
import skeleton from '../app/images/skeleton.png';
import zombie from '../app/images/zombie.png';
import vampire from '../app/images/vampire.png';
import miner from '../app/images/player.png';
import dirtTiles from '../app/images/dirt_tiles.png';
import scorch from '../app/images/scorch_01.png';
import { selectEnemyById, doAttack as enemyAttacking, attackPlayer, spawnEnemies, moveEnemy, spawnEnemy, selectAllEnemies, moveEnemyTo, startAttacking, killEnemy, STATUS as EnemyStatus } from '../features/enemies/enemiesSlice';
import store, { listener } from '../app/store';
import CONSTANTS from '../app/utils';
import { addOres, addCores, selectAI, getToolCooldown, getToolStrength, buy, getCost as getUpgradeCost, canBuy, selectCores, selectOres, useWeapon as playerAttacking, isActive as playerIsActive, attack as playerAttack, think as playerThink, reset as resetPlayer, startNewGame, isDead, takeDamage as playerTakesDamage, startMap, selectPlayer, STATUS as PlayerStatus, dig, movePlayerTo, fall, isIdle, STATUS, startDigging } from '../features/player/playerSlice';
import { selectTopLevel, selectMap, setLevelCleared, dig as worldDig } from '../features/world/worldSlice';
import { getCost as getBuildingCost, canBuild, build, getByLevel, run as runBuilding, doBuild, use as doUseBuilding, selectById as selectBuildingById } from '../features/buildings/buildingsSlice';
import HealthBar from '../features/visual/healthBar';
import LaserDefense from '../features/visual/laserDefense';
import ItemAlert from '../features/visual/itemAlert';

const WIDTH = 352;
const HEIGHT = 800;

let emitter;
let deathEmitter;
const showDigHit = (x, y) => {
    // TODO: define player width somewhere
    emitter.setPosition(x * CONSTANTS.TILE_WIDTH + 16, y * CONSTANTS.TILE_HEIGHT);
    emitter.resume();
    emitter.explode(5);
}
const enemyDieEffect = (x, y) => {
    deathEmitter.setPosition(x, y);
    deathEmitter.resume();
    deathEmitter.explode(20);
}

function updateSim(dt) {
    let state = store.getState();
    if(playerIsActive(state)) {
        const enemies = selectAllEnemies(state);
        enemies.forEach(e => {
            if(e.status === EnemyStatus.MOVING) {
                store.dispatch(moveEnemy(dt, e.id));
            } else if(e.status === EnemyStatus.ATTACKING) {
                store.dispatch(attackPlayer(dt, e.id))
            }
        })

        // run the buildings
        for(let level = 1; level <= CONSTANTS.WORLD_DEPTH; level++) {
            const building = getByLevel(state, level);
            if(!building) break;
            store.dispatch(runBuilding(dt, building.id));
        }
        
        store.dispatch(playerThink(dt));
        state = store.getState();
    }
        
    const player = selectPlayer(state);

    if(playerIsActive(state)) {
        if(player.status === STATUS.DIGGING || player.status === STATUS.ATTACKING) {
            store.dispatch(spawnEnemies(dt));
        }
    }

    if(player.status === PlayerStatus.DIGGING) {
        store.dispatch(dig(dt));
        store.dispatch(spawnEnemies(dt));
    } else if(player.status === PlayerStatus.FALLING) {
        store.dispatch(fall(dt));
    } else if(player.status === PlayerStatus.ATTACKING) {
        store.dispatch(playerAttack(dt));
    }
}

let playerHealthBar;
let mainLight;
let digLight;
let mainLightOffset = -30;
class OverlayScene extends Scene {
    constructor() {
       super({key: 'Overlay', active: true});
    }

    create() {
        const state = store.getState();
        const player = selectPlayer(state);
        playerHealthBar = new HealthBar(this, 2 * CONSTANTS.MINE_BORDER_TILES * CONSTANTS.TILE_WIDTH, CONSTANTS.TILE_HEIGHT / 2, player.hp.current, player.hp.total, 100);
    }
}

class LoadingScene extends Scene {
    DT = 1000 / 20;

    create() {
        const state = store.getState();
        const player = selectPlayer(state);

        const px = player.location.x;
        const py = player.location.y;
        this.player = {
            sprite: this.add.sprite(player.location.x * CONSTANTS.TILE_WIDTH, player.location.y * CONSTANTS.TILE_HEIGHT, 'miner').setPipeline('Light2D'),
            prevPosition: {x: px, y: py},
            position: {x: px, y: py}
        }
        this.player.sprite.setScale(0.05, 0.05);
        this.player.sprite.setOrigin(-0.15, 1);
        this.player.sprite.setDepth(10000);

        this.particles = this.add.particles('scorch');
        this.deltaMultiplier = 1;

        this.input.keyboard.on('keyup', event => {
            if(event.keyCode === Phaser.Input.Keyboard.KeyCodes.PLUS || event.keyCode === Phaser.Input.Keyboard.KeyCodes.NUMPAD_ADD) {
                this.deltaMultiplier += 1;
            } else if(event.keyCode === Phaser.Input.Keyboard.KeyCodes.MINUS || event.keyCode === Phaser.Input.Keyboard.KeyCodes.NUMPAD_SUBTRACT) {
                this.deltaMultiplier = Math.max(this.deltaMultiplier - 1, 1);
            } else if(event.keyCode === Phaser.Input.Keyboard.KeyCodes.NUMPAD_ZERO) {
                this.deltaMultiplier = 1;
            }
            console.log('game speed', this.deltaMultiplier);
        })

        this.setupLights();

        emitter = this.particles.createEmitter({
            active: false,
            alpha: { start: 1, end: 0 },
            scale: { start: 0.05, end: 0.01 },
            //tint: { start: 0xf5ec42, end: 0xc2081a },
            speed: 300,
            accelerationY: { min: -300, max: 100 },
            angle: { min: -55, max: -125 },
            rotate: { min: -180, max: 180 },
            lifespan: { min: 400, max: 1200 },
            blendMode: 'ADD',
            //frequency: 50,
            //maxParticles: 15,
            tint: [ 0xf5ec42, 0xc2081a ],
            x: WIDTH/2,
            y: HEIGHT/2,
            particleBringToTop: true,
            gravityY: 1350
        });

        deathEmitter = this.particles.createEmitter({
            active: false,
            alpha: { start: 1, end: 0 },
            scale: { start: 0.05, end: 0.01 },
            //tint: { start: 0xf5ec42, end: 0xc2081a },
            speed: 400,
            accelerationY: { min: -300, max: 100 },
            angle: { min: 0, max: 360 },
            rotate: { min: -180, max: 180 },
            lifespan: { min: 100, max: 300 },
            blendMode: 'ADD',
            //frequency: 50,
            //maxParticles: 15,
            tint: [ 0xffffff, 0x936029 ],
            x: WIDTH/2,
            y: HEIGHT/2,
            particleBringToTop: true,
            gravityY: 1350
        });

        //this.levelHealthBar = null;
        this.enemiesById = {};
        this.accumulator = 0;
        listener.cb = (type, payload) => {
            if(type === spawnEnemy.type) {
                const {id, position, type} = payload;
                //console.log('spawn', position.x, position.y, id)
                this.spawnEnemy(id, position.x, position.y, type);
            } else if(type === killEnemy().type) {
                //const id = payload;
                //console.log('kill', payload)      

                const enemy = selectEnemyById(store.getState(), payload);
                enemyDieEffect(enemy.position.x * CONSTANTS.TILE_WIDTH, (enemy.position.y * CONSTANTS.TILE_HEIGHT) - (CONSTANTS.TILE_HEIGHT / 2));          
                this.killEnemy(payload);
            } else if(type === moveEnemyTo().type) {
                const {id, x, y} = payload;
                //console.log('move', id, x, y)
                const enemy = this.enemiesById[id];
                enemy.prevPosition = enemy.position;
                enemy.position = {x, y};                                  
            } else if(type === setLevelCleared().type) {
                const level = payload;
                this.tileMap.fill(63, CONSTANTS.MINE_BORDER_TILES, level, CONSTANTS.WORLD_WIDTH-(2*CONSTANTS.MINE_BORDER_TILES), 1);
                mainLight.y += CONSTANTS.TILE_HEIGHT;
                digLight.setVisible(false);
                //this.levelHealthBar.destroy();
                //this.levelHealthBar = null;
                const bounds = this.cameras.main.getBounds();
                const extraHeight = Math.min(((level+1)*CONSTANTS.TILE_HEIGHT), CONSTANTS.WORLD_DEPTH*CONSTANTS.TILE_HEIGHT);
                this.cameras.main.setBounds(0, bounds.y, WIDTH, HEIGHT + extraHeight);
            } else if(type === movePlayerTo().type) {
                const {x, y} = payload;
                this.player.prevPosition = this.player.position;
                this.player.position = {x, y};
            } else if(type === startDigging().type) {
                this.player.prevPosition = this.player.position;
            } else if(type === startAttacking().type) {
                //console.log('start attacking', payload.id);
                digLight.setVisible(false);
                this.enemiesById[payload.id].prevPosition = this.enemiesById[payload.id].position;
            } else if(type === worldDig().type) {               
                const {level} = payload.payload;
                showDigHit(this.player.position.x, level);
                //const levelStatus = selectLevel(store.getState(), level);
                /* if(!this.levelHealthBar) {
                    this.levelHealthBar = new HealthBar(this, 2 * CONSTANTS.MINE_BORDER_TILES * CONSTANTS.TILE_WIDTH, level * CONSTANTS.TILE_HEIGHT + (CONSTANTS.TILE_HEIGHT / 4), levelStatus.hp - amount, levelStatus.totalHp);
                } else {
                    this.levelHealthBar.decrease(amount);
                } */
                digLight.y = level * CONSTANTS.TILE_HEIGHT;
                digLight.setVisible(true);
                
                const totalHp = payload.totalHp;
                const hp = payload.hp;                
                for(let i = 0; i < CONSTANTS.WORLD_WIDTH - (2 * CONSTANTS.MINE_BORDER_TILES); i++) {
                    let tile = this.tileMap.getTileAt(i + 1, level)

                    if(i !== Math.floor((CONSTANTS.WORLD_WIDTH - (2 * CONSTANTS.MINE_BORDER_TILES)) / 2)) {
                        let randomNumber = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
                        tile.pixelY = (level * CONSTANTS.TILE_HEIGHT) + randomNumber
                    }
                    
                    if(hp < (totalHp * .75)) {                    
                        tile.setAlpha(0.75);                      
                    }    
                    if(hp < (totalHp * .50)) {
                        tile.setAlpha(0.50);                        
                    } 
                    if(hp < (totalHp * .25)) {
                        tile.setAlpha(0.25);                        
                    }
                }
            } else if(type === addOres().type) { 
                let amount = payload.amount;
                const level = selectTopLevel(store.getState());
                new ItemAlert(this, level, 'Ore', amount);
            } else if(type === addCores().type) { 
                let amount = payload.amount;
                const level = selectTopLevel(store.getState());
                new ItemAlert(this, level, 'Core', amount);            
            } else if(type === playerTakesDamage().type) {
                const {amount} = payload;
                playerHealthBar.decrease(amount);
            } else if(type === resetPlayer().type) {
                playerHealthBar.reset();
                this.lights.destroy();
                this.setupTilemap();
                const state = store.getState();
                const player = selectPlayer(state);
                this.player.prevPosition = player.location;
                this.player.position = player.location;
                this.setupLights();
            } else if(type === doBuild.type) {                
                const {type, level} = payload;
                const x = Math.floor(CONSTANTS.WORLD_WIDTH/2);
                const y = level - 1;
                const tileTypeMap = {
                    laserDefense: 6,
                    oreGenerator: 3
                }
                this.tileMap.putTileAt(tileTypeMap[type], x, y);
            } else if(type === doUseBuilding().type) {
                const {id} = payload;
                const building = selectBuildingById(store.getState(), id);
                if(building.type === 'laserDefense') {
                    new LaserDefense(this, building.level);
                }
            } else if(type === playerAttacking().type) {
                const player = selectPlayer(store.getState());
                new LaserDefense(this, player.location.y, 0x0000ff);
            } else if(type === enemyAttacking().type) {
                const {id} = payload;
                const enemy = selectEnemyById(store.getState(), id);
                new LaserDefense(this, enemy.position.y, 0x00ff00);
            }
        }        

        this.setupTilemap();
    }
    preload() {
        this.load.image('skeleton', skeleton);
        this.load.image('vampire', vampire);
        this.load.image('zombie', zombie);
        this.load.image('miner', miner);
        this.load.image('dirtTiles', dirtTiles);
        this.load.image('scorch', scorch);
    }
    render(delta) {
    }
    setupLights() {
        this.lights.enable().setAmbientColor(0x555555);

        this.lights.addLight(10, -20, 300).setIntensity(2);
        this.lights.addLight(WIDTH - 10, -20, 300).setIntensity(2);

        mainLight = this.lights.addLight(WIDTH / 2, mainLightOffset, 600).setColor(0xffffff).setIntensity(1.5);

        
        // for reusable lights
        digLight = this.lights.addLight(352/2, 0, 30).setColor(0xff0000).setIntensity(4.0);
        digLight.setVisible(false);
        //laserLight = this.lights.addLight(WIDTH / 2 + 5, 0, 400).setColor(0xffffff).setIntensity(4.0);
        //laserLight.setVisible(false);
    }
    setupTilemap() {
        const state = store.getState();
        const map = selectMap(state);

        //const EMPTY_TILE = 63;
        const DIRT_TILE = 43;
        const STONE_TILE = 19;
        //const FLOOR_TILE = 32;
        //const GROUND_TILE = 22;
        const IRON_TILE = 16;
        const DIAMOND_TILE = 0;

        const world = [];

        const TYPE_TO_TILE = {
            [CONSTANTS.GROUND_TYPE.DIRT]: DIRT_TILE,
            [CONSTANTS.GROUND_TYPE.STONE]: STONE_TILE,
            [CONSTANTS.GROUND_TYPE.IRON]: IRON_TILE,
            [CONSTANTS.GROUND_TYPE.DIAMOND]: DIAMOND_TILE,
        }

        function addRow(tile) {
            const row = [];
            for(let i = 0; i < CONSTANTS.WORLD_WIDTH; i++) {
                row.push(tile);
            }
            world.push(row);
        }

        map.forEach(level => {
            addRow(TYPE_TO_TILE[level.type])
        })

        if(this.tileMap) {
            this.tileMap.destroy();
        }
        /* if(this.levelHealthBar) {
            this.levelHealthBar.destroy();
            this.levelHealthBar = null;            
        } */
        this.tileMap = this.make.tilemap({ data: world, tileWidth: CONSTANTS.TILE_WIDTH, tileHeight: CONSTANTS.TILE_HEIGHT }); 
        var tiles =  this.tileMap.addTilesetImage('dirtTiles', null, 32, 32);
        this.tileMap.createLayer(0, tiles, 0, 0).setPipeline('Light2D');

        const lowestY = -CONSTANTS.TILE_HEIGHT*(HEIGHT/CONSTANTS.TILE_HEIGHT-CONSTANTS.TOP_HEIGHT);
        this.cameras.main.setBounds(0, lowestY, WIDTH, HEIGHT);
        this.cameras.main.scrollY = lowestY;
        var cursors = this.input.keyboard.createCursorKeys();
        var controlConfig = {
            camera: this.cameras.main,
            //left: cursors.left,
            //right: cursors.right,
            up: cursors.up,
            down: cursors.down,
            speed: 1
        };
        this.controls = new Phaser.Cameras.Controls.FixedKeyControl(controlConfig);

        this.input.on('wheel', function (pointer, gameObjects, deltaX, deltaY, deltaZ) {
           
            this.cameras.main.scrollY += deltaY * 0.5;  
    
        });        
    }
    update(time, delta) {
        // dev tool
        delta *= this.deltaMultiplier;

        this.controls.update(delta);

        let frameTime = delta > 250 ? 250 : delta;

        this.accumulator += frameTime;

        while(this.accumulator >= this.DT) {
            updateSim(this.DT);
            this.accumulator -= this.DT;
        }

        const alpha = this.accumulator / this.DT;
        
        // don't move enemies if player is dead, prevents jitter
        if(playerIsActive(store.getState())) {            
            for(const enemy of Object.values(this.enemiesById)) {
                const newX = Phaser.Math.Interpolation.Linear([enemy.prevPosition.x, enemy.position.x], alpha);
                const newY = Phaser.Math.Interpolation.Linear([enemy.prevPosition.y, enemy.position.y], alpha);
                enemy.sprite.setPosition(newX*CONSTANTS.TILE_WIDTH, newY*CONSTANTS.TILE_HEIGHT);
            }
        }

        
        const newPlayerY = Phaser.Math.Interpolation.Linear([this.player.prevPosition.y, this.player.position.y], alpha);
        this.player.sprite.setY(newPlayerY*CONSTANTS.TILE_HEIGHT);

    }

    spawnEnemy(id, x, y, type) {
        const enemy = {
            id,
            sprite: this.add.sprite(x*CONSTANTS.TILE_WIDTH, y*CONSTANTS.TILE_HEIGHT, type).setPipeline('Light2D'),
            prevPosition: {x, y},
            position: {x, y}
        }
        enemy.sprite.setScale(0.15, 0.15);
        enemy.sprite.setOrigin(0.5, 1);
        if(x < CONSTANTS.WORLD_WIDTH / 2) {
            enemy.sprite.setFlipX(true)
        }
        //enemy.sprite.setVisible(true);
        //enemy.sprite.setDepth(1000);
        this.enemiesById[enemy.id] = enemy;
    }

    killEnemy(id) {
        const enemy = this.enemiesById[id];
        delete this.enemiesById[id];
        enemy.sprite.destroy();
    }
}
function Gui() {
    
    const dispatch = useDispatch()
    const playerLevel = useSelector(selectTopLevel);
    const playerIsIdle = useSelector(isIdle);
    const playerIsDead = useSelector(isDead);
    const playerCanBuildLaser = useSelector(canBuild('laserDefense'));
    const playerCanBuildOreGen = useSelector(canBuild('oreGenerator'));
    const playerOres = useSelector(selectOres);
    const playerCores = useSelector(selectCores);
    const playerAI = useSelector(selectAI);
    const laserDefenseCost = useSelector(getBuildingCost('laserDefense'));
    const oreGeneratorCost = useSelector(getBuildingCost('oreGenerator'));
    const canBuyToolCooldown = useSelector(canBuy('toolCooldown'));
    const canBuyToolStrength = useSelector(canBuy('toolStrength'));
    const canBuyToolCooldownReduction = useSelector(canBuy('toolCooldownReduction'));
    const canBuyToolStrengthBonus = useSelector(canBuy('toolStrengthBonus'));
    const toolCooldownCost = useSelector(getUpgradeCost('toolCooldown'));
    const toolStrengthCost = useSelector(getUpgradeCost('toolStrength'));
    const toolCooldownReductionCost = useSelector(getUpgradeCost('toolCooldownReduction'));
    const toolStrengthBonusCost = useSelector(getUpgradeCost('toolStrengthBonus'));
    const toolCooldown = useSelector(getToolCooldown);
    const toolStrength = useSelector(getToolStrength);

    useEffect(() => {
        const config = {
            type: Phaser.AUTO,
            parent: 'visual',
            pixelArt: true,
            width: WIDTH,
            height: HEIGHT,           
            scene: [LoadingScene, OverlayScene]
        };
        
        new Phaser.Game(config);
    }, [])

    return (
        <Container>
            <Row>
                <Col>
                    <Row className="mt-1">
                        <Button onClick={() => dispatch(startMap())} disabled={!playerIsIdle}>Dig</Button> 
                        {playerIsDead &&
                        <React.Fragment>
                            <Row className="mt-1">
                                You dead.
                            </Row>
                            <Row className="mt-1">
                                <Button onClick={() => dispatch(startNewGame())}>Try Again</Button>
                            </Row>
                        </React.Fragment>
                        }
                    </Row>
                    <Row className="mt-1">
                        <Tabs className="mb-3">
                            <Tab eventKey="home" title="Buildings">        
                                <Row className="mt-1">
                                    <Button onClick={() => dispatch(build('laserDefense'))} disabled={!playerCanBuildLaser}>Laser Defense ({laserDefenseCost} ores)</Button>
                                </Row>  
                                <Row className="mt-1">
                                    <Button onClick={() => dispatch(build('oreGenerator'))} disabled={!playerCanBuildOreGen}>Ore Generator ({oreGeneratorCost} ores)</Button>
                                </Row>
                            </Tab>
                            <Tab eventKey="profile" title="Upgrades"> 
                                <Row className="mt-1">
                                    <Button onClick={() => dispatch(buy('toolCooldown'))} disabled={!canBuyToolCooldown}>Tool Cooldown -10% ({toolCooldownCost} cores)</Button>
                                </Row>
                                <Row className="mt-1">
                                    <Button onClick={() => dispatch(buy('toolStrength'))} disabled={!canBuyToolStrength}>Tool Strength +50% ({toolStrengthCost} cores)</Button>
                                </Row>
                            </Tab>
                            <Tab eventKey="contact" title="AI">
                                <Row className="mt-1">
                                    <Button onClick={() => dispatch(buy('toolCooldownReduction'))} disabled={!canBuyToolCooldownReduction}>Tool Cooldown -50ms ({toolCooldownReductionCost} AI)</Button>
                                </Row>
                                <Row className="mt-1">
                                    <Button onClick={() => dispatch(buy('toolStrengthBonus'))} disabled={!canBuyToolStrengthBonus}>Tool Strength +1 ({toolStrengthBonusCost} AI)</Button>
                                </Row>
                            </Tab>
                        </Tabs> 
                    </Row>    
                </Col>
                <Col id="visual"/>
                <Col>
                    <Row className="mt-1">
                        <Col xs={4}>
                            <div><b>Resouces:</b></div>
                            <div>Ores:  <span className="float-end">{playerOres.toFixed()}</span></div>
                            <div>Cores: <span className="float-end">{playerCores.toFixed()}</span></div>
                            <div>AI: <span className="float-end">{playerAI.toFixed()}</span></div>                                                    
                        </Col>                
                        <Col>
                            <div><b>Mining Tool:</b></div>
                            <div>Cooldown: <span className="float-end">{toolCooldown} ms</span></div>
                            <div>Strength: <span className="float-end">{toolStrength}</span></div>
                        </Col>      
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <div><b>Level:</b></div>
                            <div><span className="display-3">{playerLevel+1}</span></div>
                        </Col>
                    </Row>                    
                </Col>
            </Row>
        </Container> 
    );
}

export default Gui;