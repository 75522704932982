import Phaser from 'phaser';
import CONSTANTS from '../../app/utils';

const BAR_WIDTH = (CONSTANTS.WORLD_WIDTH - (2 * CONSTANTS.MINE_BORDER_TILES)) * CONSTANTS.TILE_WIDTH;

export default class LaserDefense extends Phaser.GameObjects.Graphics {

  constructor(scene, level, color = 0xff0000 ) {
    super(scene);
    this.setDepth(1000);
    scene.add.existing(this);
    this.setPosition(CONSTANTS.TILE_WIDTH/2, (level-1) * CONSTANTS.TILE_HEIGHT/2);
    this.color = color;
    this.height = CONSTANTS.TILE_HEIGHT / 2;
    this.yPos = this.y;
    this.light = scene.lights.addLight(352/2, (level * CONSTANTS.TILE_HEIGHT) - (CONSTANTS.TILE_HEIGHT / 2), 30).setColor(color).setIntensity(4.0);
    this.alpha = .25;
  }

  preUpdate(time, dt) {
    this.clear();    
    if(this.alpha <= 0) {
      this.scene.lights.removeLight(this.light);
      return this.destroy();
    } 
    this.setAlpha(this.alpha - (2 * dt/1000));    
    this.height = this.height - (1.75 * dt/1000 * CONSTANTS.TILE_HEIGHT);
    this.yPos = this.yPos + (1.75 * dt/1000 * CONSTANTS.TILE_HEIGHT/2);    

    this.fillStyle(this.color);
    this.fillRect(this.x, this.yPos, BAR_WIDTH, this.height);
  }

}