import { configureStore } from '@reduxjs/toolkit'
import materialReducer from '../features/materials/materialSlice'
import enemiesReducer from '../features/enemies/enemiesSlice';
import playerReducer from '../features/player/playerSlice';
import worldReducer from '../features/world/worldSlice';
import buildingsReducer from '../features/buildings/buildingsSlice';

export const listener = {
  cb: null
}

const phaserWrapper = store => next => action => {
  if(listener.cb !== null) {
    listener.cb(action.type, action.payload)
  }
  next(action);
}


export default configureStore({
  reducer: {
    enemies: enemiesReducer,
    material: materialReducer,
    player: playerReducer,
    world: worldReducer,
    buildings: buildingsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(phaserWrapper)
})