import { createSlice } from '@reduxjs/toolkit'
import CONSTANTS from '../../app/utils';
import { digWorld, selectLevel, selectTopLevel, resetWorld } from '../world/worldSlice';
import { resetSpawns, receiveAttack as attackEnemy, selectEnemiesByLevel, killAllEnemies, setupEnemySpawns } from '../enemies/enemiesSlice';
import { reset as resetBuildings } from '../buildings/buildingsSlice';

const MINING_TOOL_BASE_COOLDOWN = 1000;
const MINING_TOOL_BASE_STRENGTH = 10;
const MINING_TOOL_BASE_COOLDOWN_COST = 5;
const MINING_TOOL_BASE_STRENGTH_COST = 5;
const MINING_TOOL_COOLDOWN_REDUCTION_COST = 5;
const MINING_TOOL_STRENGTH_BONUS_COST = 5;

export const STATUS = {
  IDLING: 'idling',
  DIGGING: 'digging',
  FALLING: 'falling',
  DEAD: 'dead',
  ATTACKING: 'attacking'
}

export const playerSlice = createSlice({
  name: 'player',
  initialState: {
    active: false,
    location: {
      x: Math.floor(CONSTANTS.WORLD_WIDTH/2),
      y: 0
    },
    status: STATUS.IDLING,
    miningTool: {
      type: 'pickaxe',
      strength: MINING_TOOL_BASE_STRENGTH,
      cooldown: MINING_TOOL_BASE_COOLDOWN,
      next: 0,
      cooldownReduction: 0,
      strengthBonus: 0,
    },
    weapon: {
      type: 'laser',
      strength: 10,
      cooldown: 2500,
      next: 0
    },
    hp: {
      current: 100,
      total: 100
    },
    resources: {
      cores: 0,
      ores: 0,
      ai: 0
    },
    luck: {
      ore: {
        chance: 0.2,
        min: 1,
        max: 1
      }
    },
    costs: {
      toolCooldown: MINING_TOOL_BASE_COOLDOWN_COST,
      toolStrength: MINING_TOOL_BASE_STRENGTH_COST,
      toolCooldownReduction: MINING_TOOL_COOLDOWN_REDUCTION_COST,
      toolStrengthBonus: MINING_TOOL_STRENGTH_BONUS_COST
    }
  },
  reducers: {
    movePlayerTo: (state, action) => {
      const {x, y} = action.payload;
      state.location = {x, y};
    },
    startDigging: (state) => {
      state.status = STATUS.DIGGING;
    },
    startFalling: (state) => {
      state.status = STATUS.FALLING;
    },
    startIdling: (state) => {
      state.status = STATUS.IDLING;
    },
    startAttacking: (state) => {
      state.status = STATUS.ATTACKING;
    },
    chargeMiningTool: (state, action) => {
      const {dt} = action.payload;
      state.miningTool.next = Math.max(state.miningTool.next - dt, 0);
    },
    chargeWeapon: (state, action) => {
      const {dt} = action.payload;
      state.weapon.next = Math.max(state.weapon.next - dt, 0);
    },
    useMiningTool: (state) => {
      state.miningTool.next = state.miningTool.cooldown;
    },
    useWeapon: (state) => {
      state.weapon.next = state.weapon.cooldown;
    },
    resetMiningTool: (state) => {
      state.miningTool.next = 0;
    },
    resetWeapon: (state) => {
      state.weapon.next = 0;
    },
    takeDamage: (state, action) => {
      const {amount} = action.payload;
      state.hp.current = Math.max(state.hp.current - amount, 0);
    },
    die: (state) => {
      state.active = false;
      state.status = STATUS.DEAD;
    },
    reset: (state) => {
      state.miningTool.next = 0;
      state.miningTool.cooldown = MINING_TOOL_BASE_COOLDOWN - state.miningTool.cooldownReduction;
      state.miningTool.strength = MINING_TOOL_BASE_STRENGTH + state.miningTool.strengthBonus;
      state.hp.current = state.hp.total;
      state.status = STATUS.IDLING;
      state.resources.cores = 0;
      state.resources.ores = 0;
      state.costs.toolCooldown = MINING_TOOL_BASE_COOLDOWN_COST;
      state.costs.toolStrength = MINING_TOOL_BASE_STRENGTH_COST;
    },
    setActive: (state, action) => {
      state.active = action.payload;
    },
    addCores: (state, action) => {
      const {amount} = action.payload;
      state.resources.cores += amount;
    },
    spendCores: (state, action) => {
      const {amount} = action.payload;
      state.resources.cores -= amount;
    },
    addOres: (state, action) => {
      const {amount} = action.payload;
      state.resources.ores += amount;
    },
    spendOres: (state, action) => {
      const {amount} = action.payload;
      state.resources.ores -= amount;
    },
    upgradeToolCooldown: (state, action) => {
      const {cooldown} = action.payload;
      state.miningTool.cooldown = cooldown;
    },
    upgradeToolStrength: (state, action) => {
      const {strength} = action.payload;
      state.miningTool.strength = strength;
    },
    increaseCost: (state, action) => {
        const {type, cost} = action.payload;
        state.costs[type] = cost;
    },
    addAI: (state, action) => {
      const {amount} = action.payload;
      state.resources.ai += amount;
    },
    spendAI: (state, action) => {
      const {amount} = action.payload;
      state.resources.ai -= amount;
    },
    upgradeToolCooldownReduction: (state, action) => {
      const {amount} = action.payload;
      state.miningTool.cooldownReduction = amount;
    },
    upgradeToolStrengthBonus: (state, action) => {
      const {amount} = action.payload;
      state.miningTool.strengthBonus = amount;
    },
  }
})

export const { 
  movePlayerTo, 
  startDigging, 
  startFalling,
  startIdling,
  chargeMiningTool,
  chargeWeapon,
  useMiningTool,
  useWeapon,
  resetMiningTool,
  resetWeapon,
  takeDamage,
  die,
  reset,
  startAttacking,
  setActive,
  addCores,
  spendCores,
  addOres,
  spendOres,
  upgradeToolCooldown,
  upgradeToolStrength,
  increaseCost,
  addAI,
  spendAI,
  upgradeToolCooldownReduction,
  upgradeToolStrengthBonus
} = playerSlice.actions

export function dig(dt) {
  return (dispatch, getState) => {
    dispatch(chargeMiningTool({dt}));

    const player = selectPlayer(getState());
    if(player.miningTool.next <= 0) {
      dispatch(digWorld({level: player.location.y, amount: player.miningTool.strength}));
      dispatch(useMiningTool());
      
      const level = selectLevel(getState(), player.location.y);      

      let oreModifier = () => {
        switch (level.type) {
          case 'stone':
            return 2
          case 'iron':
            return 4
          case 'diamond':
            return 6          
          default:
            return 0
        }
      }
      if(Math.random() <= (player.luck.ore.chance + (oreModifier() / 20))) {
        dispatch(addOres({amount: (Math.random() * ((player.luck.ore.max + oreModifier()) - player.luck.ore.min)) + player.luck.ore.min}));        
      }
      
      if(level.hp <= 0) {
        dispatch(addOres({amount: player.location.y+1}))
        dispatch(setActive(false));
        dispatch(startFalling())
      }
    }
  }
}

function startNextLevel(dispatch, level) {
  dispatch(setupEnemySpawns({level}));
  dispatch(startDigging());
  dispatch(setActive(true));  
}

export function fall(dt) {
  return (dispatch, getState) => {
    const topLevel = selectTopLevel(getState());
    const player = selectPlayer(getState());

    if(player.location.y === topLevel) {
      return startNextLevel(dispatch, topLevel);
    }

    const fallAmount = Math.min(dt/1000*5, topLevel - player.location.y);
    dispatch(movePlayerTo({x: player.location.x, y: player.location.y + fallAmount}));
  }
}

export function startMap() {
  return (dispatch, getState) => {
    dispatch(resetMiningTool());
    dispatch(resetWeapon());
    const topLevel = selectTopLevel(getState());    
    startNextLevel(dispatch, topLevel);
  }
}

export function receiveAttack(amount) {
  return (dispatch, getState) => {
    dispatch(takeDamage({amount}));
    const player = selectPlayer(getState());
    if(player.hp.current <= 0) {
      dispatch(die());
      dispatch(addAI({amount: player.location.y}));
    }
  }
}

export function startNewGame() {
  return (dispatch, getState) => {
    // kill all enemies
    dispatch(killAllEnemies());
    dispatch(resetSpawns());
    // reset buildings
    dispatch(resetBuildings());
    // reset world
    dispatch(resetWorld());
    // reset player
    dispatch(movePlayerTo({x: Math.floor(CONSTANTS.WORLD_WIDTH/2), y: 0}));
    dispatch(reset());
  }
}

export function think(dt) {
  return (dispatch, getState) => {
    const player = selectPlayer(getState());
    if(player.status === STATUS.DEAD) return;
    const enemiesInRange = selectEnemiesByLevel(getState(), player.location.y);
    if(enemiesInRange.length > 0 && player.status !== STATUS.ATTACKING) {
      dispatch(startAttacking());
    } else if(enemiesInRange.length <= 0 && player.status !== STATUS.DIGGING) {
      dispatch(startDigging());
    }
  }
}

export function attack(dt) {
  return (dispatch, getState) => {
    dispatch(chargeWeapon({dt}));

    const player = selectPlayer(getState());
    const enemiesInRange = selectEnemiesByLevel(getState(), player.location.y);
    if(player.weapon.next <= 0) {
      if(player.weapon.type === 'laser') {
        // hits all enemies in range
        dispatch(useWeapon());
        for(const enemy of enemiesInRange) {
          dispatch(attackEnemy({id: enemy.id, amount: player.weapon.strength}));
        }
      }
    }
  }
}

export function buy(type) {
  return (dispatch, getState) => {
      const state = getState();
      if(canBuy(type)(state)) {
          const player = selectPlayer(getState());
          const cost = getCost(type)(state);          
          switch(type) {
            case 'toolCooldown':
              dispatch(spendCores({amount: cost}));
              dispatch(upgradeToolCooldown({cooldown: Math.floor(player.miningTool.cooldown * 0.9)}))
              break;
            case 'toolStrength':
              dispatch(spendCores({amount: cost}));
              dispatch(upgradeToolStrength({strength: Math.ceil(player.miningTool.strength * 1.5)}))
              break;
            case 'toolCooldownReduction':
              dispatch(spendAI({amount: cost}));
              dispatch(upgradeToolCooldownReduction({amount: player.miningTool.cooldownReduction + 50}))              
              break;
            case 'toolStrengthBonus':
              dispatch(spendAI({amount: cost}));
              dispatch(upgradeToolStrengthBonus({amount: player.miningTool.strengthBonus + 1})) 
              break;
            default:
              break;
          }
          dispatch(increaseCost({type, cost: Math.ceil(cost * 1.5)}));
      }        
  }
}

export const selectPlayer = (state) => state.player;
export const isIdle = (state) => state.player.status === STATUS.IDLING;
export const isDead = (state) => state.player.status === STATUS.DEAD;
export const isActive = (state) => state.player.active;
export const selectOres = (state) => state.player.resources.ores;
export const selectCores = (state) => state.player.resources.cores;
export const selectAI = (state) => state.player.resources.ai;
export const getToolCooldown = (state) => state.player.miningTool.cooldown;
export const getToolStrength = (state) => state.player.miningTool.strength;

export const getCost = type => (state) => state.player.costs[type];
export const canBuy = type => (state) => {
    const cost = getCost(type)(state);
    let playerResources;
    switch(type) {
      case 'toolCooldown':
      case 'toolStrength':
        playerResources = selectCores(state);
        break;
      case 'toolCooldownReduction':
      case 'toolStrengthBonus':
        playerResources = selectAI(state);
        break;
      default:
        break;
    }
    return playerResources >= cost;
}

export default playerSlice.reducer
