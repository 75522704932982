import { createSlice } from '@reduxjs/toolkit'
import CONSTANTS from '../../app/utils';
import { clearLevel } from '../buildings/buildingsSlice';

const HP_MULTIPLIERS_PER_TYPE = {
  [CONSTANTS.GROUND_TYPE.DIRT]: 1,
  [CONSTANTS.GROUND_TYPE.STONE]: 2,
  [CONSTANTS.GROUND_TYPE.IRON]: 4,
  [CONSTANTS.GROUND_TYPE.DIAMOND]: 8,
}

const BREAKPOINTS = [
  {level: 80, type: CONSTANTS.GROUND_TYPE.DIAMOND},
  {level: 50, type: CONSTANTS.GROUND_TYPE.IRON},
  {level: 20, type: CONSTANTS.GROUND_TYPE.STONE},
  {level: 0, type:  CONSTANTS.GROUND_TYPE.DIRT}
]

const INITIAL_HP = 100;
const HP_MULTIPLIER_PER_LEVEL = 1.20;

function generateWorld() {
  const world = [];
  let lastHP = INITIAL_HP;

  function addRow(type, level) {
    const typeMultiplier = HP_MULTIPLIERS_PER_TYPE[type];
    const baseHP = level === 0 ? lastHP : lastHP * HP_MULTIPLIER_PER_LEVEL;
    lastHP = baseHP;
    world.push({
      type,
      hp: baseHP * typeMultiplier,
      totalHp: baseHP * typeMultiplier
    })
  }
  
  for(let i = 0; i < CONSTANTS.WORLD_DEPTH; i++) {
    const breakpoint = BREAKPOINTS.find(b => b.level <= i);
    addRow(breakpoint.type, i);
  }

  return world;
}

function getWorld() {
  const map = generateWorld();
  const mapByLevel = map.reduce((byLevel, level, i) => {
    byLevel[i] = level;
    return byLevel;
  }, {})
  return {map, mapByLevel};
}

const initialWorld = getWorld();
export const worldSlice = createSlice({
  name: 'world',
  initialState: {
    map: initialWorld.map,
    mapByLevel: initialWorld.mapByLevel,
    topLevel: 0
  },
  reducers: {
    dig: (state, action) => {
      const {level, amount} = action.payload.payload;
      state.mapByLevel[level].hp = Math.max(0, state.mapByLevel[level].hp - amount);
    },
    setLevelCleared: (state, action) => {
      state.mapByLevel[action.payload].type = CONSTANTS.GROUND_TYPE.CLEAR;
      state.topLevel++;
    },
    resetWorld: (state) => {
      const world = getWorld();
      state.map = world.map;
      state.mapByLevel = world.mapByLevel;
      state.topLevel = 0;
    }
  }
})

export const { setLevelCleared, dig, resetWorld } = worldSlice.actions

export const selectMap = (state) => state.world.map;
export const selectTopLevel = (state) => state.world.topLevel;
export const selectLevel = (state, level) => state.world.mapByLevel[level];

export function digWorld(payload) {
  return (dispatch, getState) => {
    const levelAndHp = {payload: payload, level: payload.level, hp: selectLevel(getState(), payload.level).hp, totalHp: selectLevel(getState(), payload.level).totalHp}
    dispatch(worldSlice.actions.dig(levelAndHp));
    const level = selectLevel(getState(), payload.level);
    if(level.hp <= 0) {
      dispatch(setLevelCleared(payload.level));
      dispatch(clearLevel(payload.level));
    }
  }
}

export default worldSlice.reducer