import Phaser from 'phaser';
import CONSTANTS from '../../app/utils';

const BAR_WIDTH = (CONSTANTS.WORLD_WIDTH - (4 * CONSTANTS.MINE_BORDER_TILES)) * CONSTANTS.TILE_WIDTH;

export default class HealthBar {

  constructor(scene, x, y, health, total, depth=0) {
    this.bar = new Phaser.GameObjects.Graphics(scene);
    this.bar.setDepth(depth);

    this.scene = scene;
    this.x = x;
    this.y = y;
    this.value = health;
    this.total = total;
    this.p = BAR_WIDTH / total;

    this.draw();

    this.scene.add.existing(this.bar);
  }

  destroy() {
    this.bar.destroy();
  }

  decrease(amount) {
    this.value -= amount;

    if (this.value < 0) {
      this.value = 0;
    }

    this.draw();

    return (this.value === 0);
  }

  reset() {
    this.value = this.total;

    this.draw();
  }

  draw() {
    this.bar.clear();

    //  BG
    this.bar.fillStyle(0x000000);
    this.bar.fillRect(this.x, this.y, BAR_WIDTH + 4, 16);

    //  Health

    this.bar.fillStyle(0xffffff);
    this.bar.fillRect(this.x + 2, this.y + 2, BAR_WIDTH, 12);

    if (this.value / this.total <= .3) {
      this.bar.fillStyle(0xff0000);
    } else if (this.value / this.total <= .6) {
      this.bar.fillStyle(0xffff00);
    } else {
      this.bar.fillStyle(0x00ff00);
    }

    var d = Math.floor(this.p * this.value);

    this.bar.fillRect(this.x + 2, this.y + 2, d, 12);
  }

}