const CONSTANTS = { 
  TILE_HEIGHT: 32,
  TILE_WIDTH: 32,
  WORLD_WIDTH: 11,
  WORLD_DEPTH: 100,
  TOP_HEIGHT: 3,
  MINE_BORDER_TILES: 1,
  GROUND_TYPE: {
    CLEAR: 'clear',
    DIRT: 'dirt',
    STONE: 'stone',
    IRON: 'iron',
    DIAMOND: 'diamond'
  }
}

export default CONSTANTS;