import { createSlice, nanoid } from '@reduxjs/toolkit'
import CONSTANTS from '../../app/utils';
import { selectTopLevel } from '../world/worldSlice';
import { addCores, receiveAttack as hitPlayer } from '../player/playerSlice';

export const STATUS = {
  MOVING: 'moving',
  ATTACKING: 'attacking'
}

const ENEMY_COOLDOWNS = {
  'skeleton': 5000,
  'zombie': 20000,
  'vampire': 40000
}

function addToByLevel(byLevel, enemyId, level) {
  const existing = byLevel[level] || [];
  existing.push(enemyId);
  byLevel[level] = existing;
}

function removeFromByLevel(byLevel, enemyId, level) {
  const index = byLevel[level].indexOf(enemyId);
  byLevel[level].splice(index, 1);
}

function getInLevels(y) {
  const minLevel = Math.floor(y);
  const maxLevel = Math.ceil(y);
  const inLevels = [];
  for(let i = minLevel; i <= maxLevel; i++) {
    inLevels.push(i);
  }
  return inLevels;
}

function getDiff(existing, newer) {
  return existing.reduce((diff, i) => {
    if(newer.indexOf(i) < 0) {
      diff.push(i);
    }
    return diff;
  }, []);
}

function getSkeletonSpawnInfo() {
  return {
    type: 'skeleton',
    amount: 1,
    cooldown: ENEMY_COOLDOWNS['skeleton'],
    next: ENEMY_COOLDOWNS['skeleton']
  }
}

function getZombieSpawnInfo() {
  return {
    type: 'zombie',
    amount: 1,
    cooldown: ENEMY_COOLDOWNS['zombie'],
    next: ENEMY_COOLDOWNS['zombie']
  }
}

function getVampireSpawnInfo() {
  return {
    type: 'vampire',
    amount: 1,
    cooldown: ENEMY_COOLDOWNS['vampire'],
    next: ENEMY_COOLDOWNS['vampire']
  }
}

function getInitialSpawns() {
  return {
    skeleton: getSkeletonSpawnInfo()
  }
}

function getEnemyInfo(type) {
  switch(type) {
    case 'skeleton':
      return {
        hp: {
          current: 5,
          total: 5
        },
        weapon: {
          type: 'sword',
          strength: 5,
          cooldown: 1500,
          next: 1500
        },
        cores: 1,
      }
    case 'zombie':
      return {
        hp: {
          current: 15,
          total: 15
        },
        weapon: {
          type: 'teeth',
          strength: 10,
          cooldown: 1500,
          next: 1500
        },
        cores: 5,
      }  
    case 'vampire':
      return {
        hp: {
          current: 25,
          total: 25
        },
        weapon: {
          type: 'claws',
          strength: 15,
          cooldown: 1500,
          next: 1500
        },
        cores: 10,
      }
    default:
      return {}
  }
}

export const enemiesSlice = createSlice({
  name: 'enemies',
  initialState: {
    byId: {
    },
    byLevel: {},
    all: [],
    spawns: getInitialSpawns()
  },
  reducers: {
    killEnemy: (state, action) => {
      const id = action.payload;
      const index = state.all.indexOf(id);
      if(index >= 0) {
        state.all.splice(index, 1);
        const enemy = state.byId[id];
        delete state.byId[id];
        if(enemy) {
          enemy.inLevels.forEach((level) => {
            removeFromByLevel(state.byLevel, enemy.id, level);
          })
        }
      }
    },
    spawnEnemy: {
      reducer: (state, action) => {
        const enemy = action.payload;
        state.byId[enemy.id] = enemy;
        state.all.push(enemy.id);
        addToByLevel(state.byLevel, enemy.id, enemy.inLevels[0]);
        state.spawns[enemy.type].next = Math.max(0, state.spawns[enemy.type].cooldown + (Math.random()*500-250))
      },
      prepare: ({x, type}) => {
        let min;
        let max;
        if(x < CONSTANTS.WORLD_WIDTH / 2) {
          // left side
          min = CONSTANTS.MINE_BORDER_TILES;
          max = (CONSTANTS.WORLD_WIDTH / 2) - 1;
        } else {
          // right side          
          min = (CONSTANTS.WORLD_WIDTH / 2);
          max = CONSTANTS.WORLD_WIDTH - 1;
        }
        const targetX = Math.random() * (max - min) + min;
        return {
          payload: {
            id: nanoid(),
            type,
            position: {
              x,
              y: 0
            },
            inLevels: getInLevels(0),
            status: STATUS.MOVING,
            targetX: targetX,
            ...getEnemyInfo(type)
          }
        }
      }
    },
    moveEnemyTo: (state, action) => {
      const {id, x, y} = action.payload;
      const enemy = state.byId[id];
      enemy.position = {x, y};
      
      const newInLevels = getInLevels(y);
      const removedFromLevels = getDiff(enemy.inLevels, newInLevels);
      const addedToLevels = getDiff(newInLevels, enemy.inLevels);
      removedFromLevels.forEach(level => removeFromByLevel(state.byLevel, id, level));
      addedToLevels.forEach(level => addToByLevel(state.byLevel, id, level));
      enemy.inLevels = newInLevels;
    },
    setupEnemySpawns: (state, action) => {
      const { level } = action.payload;      
      let spawnRate = 1 + Math.floor(level/5);
      state.spawns.skeleton.cooldown = ENEMY_COOLDOWNS['skeleton'] / (spawnRate + (level / 10));
      console.log('Level', level, 'Skel CD:', state.spawns.skeleton.cooldown.toFixed(2), 'Rate:', spawnRate, '- Cooldown reduced by', (100 - (100 * (state.spawns.skeleton.cooldown / ENEMY_COOLDOWNS['skeleton']))).toFixed(2) + "%");
      // add some variability to spawn rate
      state.spawns.skeleton.next = Math.max(0, state.spawns.skeleton.cooldown + (Math.random()*500-250));

      if(level >= 10) {
        state.spawns.zombie = {
          ...getZombieSpawnInfo(),
          cooldown: ENEMY_COOLDOWNS['zombie'] / (spawnRate + (level / 10))
        }
        state.spawns.zombie.next = Math.max(0, state.spawns.zombie.cooldown + (Math.random()*500-250))
      }
      if(level >= 20) {
        state.spawns.vampire = {
          ...getVampireSpawnInfo(),
          cooldown: ENEMY_COOLDOWNS['vampire'] / (spawnRate + (level / 10))
        }
        state.spawns.vampire.next = Math.max(0, state.spawns.vampire.cooldown + (Math.random()*500-250))
      }
    },
    prepareEnemySpawn: (state, action) => {
      const {dt} = action.payload;
      const spawns = Object.values(state.spawns);
      spawns.forEach(spawnInfo => {
        spawnInfo.next = Math.max(spawnInfo.next - dt, 0);
      })
    },
    prepareWeapon: (state, action) => {
      const {id, dt} = action.payload;
      state.byId[id].weapon.next = Math.max(state.byId[id].weapon.next - dt, 0);
    },
    startAttacking: (state, action) => {
      const {id} = action.payload;
      state.byId[id].status = STATUS.ATTACKING;
    },
    doAttack: (state, action) => {
      const {id} = action.payload;
      state.byId[id].weapon.next = state.byId[id].weapon.cooldown;
    },
    takeDamage: (state, action) => {
      const {id, amount} = action.payload;
      const enemy = state.byId[id];
      enemy.hp.current = Math.max(enemy.hp.current - amount, 0);
    },
    resetSpawns: (state) => {
      state.spawns = getInitialSpawns()
    }
  }
})

// Action creators are generated for each case reducer function
export const { resetSpawns, takeDamage, prepareWeapon, doAttack, spawnEnemy, moveEnemyTo, killEnemy, setupEnemySpawns, prepareEnemySpawn, startAttacking } = enemiesSlice.actions

export const selectEnemyById = (state, id) => state.enemies.byId[id];
export const selectAllEnemies = (state) => state.enemies.all.map(id => state.enemies.byId[id]);
export const selectEnemySpawns = (state) => state.enemies.spawns;
export const selectEnemiesByLevel = (state, level) => (state.enemies.byLevel[level] ?? []).map(id => state.enemies.byId[id]);

export default enemiesSlice.reducer

export function spawnEnemies(dt) {
  return (dispatch, getState) => {
    dispatch(prepareEnemySpawn({dt}));
    const spawns = selectEnemySpawns(getState());
    Object.values(spawns).forEach(spawnInfo => {
      if(spawnInfo.next <= 0) {
        const x = Math.random() < 0.5 ? -1 : CONSTANTS.WORLD_WIDTH;
        dispatch(spawnEnemy({x, type: spawnInfo.type}));
      }
    })
  }
}

export function moveEnemy(dt, id) {
  return (dispatch, getState) => {
    const enemy = selectEnemyById(getState(), id);
    const topLevel = selectTopLevel(getState());

    if(enemy.position.x === enemy.targetX && enemy.position.y === topLevel) {
      return dispatch(startAttacking({id}));
    }

    let dy = 0;
    if(enemy.position.y !== topLevel) {
      if(enemy.position.x > CONSTANTS.MINE_BORDER_TILES && enemy.position.x < CONSTANTS.WORLD_WIDTH - CONSTANTS.MINE_BORDER_TILES) {
        // not on the top level and past the border, need to fall
        const remainingDistance = topLevel - enemy.position.y;
        dy = Math.min(dt / 1000 * 5, remainingDistance);
      }
    }
    let dx = 0;
    if(enemy.position.x !== enemy.targetX) {
      const remainingDistance = Math.abs(enemy.targetX - enemy.position.x);
      dx = Math.min(dt / 1000 * 5, remainingDistance);
      if(enemy.position.x > enemy.targetX) {
        dx *= -1;
      }
    }
    if(dx || dy) {
      dispatch(moveEnemyTo({
        id,
        x: enemy.position.x + dx,
        y: enemy.position.y + dy
      }))
    }
  }
}

export function attackPlayer(dt, id) {
  return (dispatch, getState) => {
    dispatch(prepareWeapon({dt, id}));
    const enemy = selectEnemyById(getState(), id);
    if(enemy.weapon.next <= 0) {
      dispatch(doAttack({id}));
      dispatch(hitPlayer(enemy.weapon.strength));
    }
  }
}

export function killAllEnemies() {
  return (dispatch, getState) => {
    const enemies = selectAllEnemies(getState());
    for(const enemy of enemies) {
      dispatch(killEnemy(enemy.id));
      dispatch(addCores({amount: enemy.cores}))
    }
  }
}

export function receiveAttack({id, amount}) {
  return (dispatch, getState) => {    
    dispatch(takeDamage({id, amount}));
    const enemy = selectEnemyById(getState(), id);
    if(enemy.hp.current <= 0) {
      dispatch(killEnemy(id));
      dispatch(addCores({amount: enemy.cores}))
    }
  }
}