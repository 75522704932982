import Phaser from 'phaser';
import CONSTANTS from '../../app/utils';

const BAR_WIDTH = (CONSTANTS.WORLD_WIDTH - (2 * CONSTANTS.MINE_BORDER_TILES)) * CONSTANTS.TILE_WIDTH;
const INNER_WIDTH = (CONSTANTS.WORLD_WIDTH - (2 * CONSTANTS.MINE_BORDER_TILES) * CONSTANTS.TILE_WIDTH) * 2;

export default class ItemAlert extends Phaser.GameObjects.Graphics {

  constructor(scene, level, type, amount ) {
    super(scene);
    this.level = level;
    this.setDepth(1000);
    scene.add.existing(this);
    this.setPosition(BAR_WIDTH/2, (level * CONSTANTS.TILE_HEIGHT) - (CONSTANTS.TILE_HEIGHT * 2) -10);
    this.posX = this.x + Math.floor(Math.random() * (INNER_WIDTH - -INNER_WIDTH + 1) + -INNER_WIDTH);
    this.posY = this.y + Math.floor(Math.random() * (5 - -20 + 1) + -20);
    this.amount = amount;
    this.type = type;
    this.fontSize = 12;
    this.colors = { 'Ore': '#e09b28', 'Core': '#28e0dc', 'Big': '#ff0000' }
    this.style = { font: `bold ${this.fontSize}px Arial`, fill: amount >= 10 ? this.colors['Big'] : this.colors[type]};
    this.text = scene.add.text(this.posX, this.posY, `+${amount} ${type}!`, this.style);
    this.text.setShadow(3, 3, 'rgba(0,0,0,0.5)', 2);
    this.text.setAngle(Math.floor(Math.random() * (5 - -5 + 1) + -5));
    this.prevTime = '';
  }

  preUpdate(time, dt) {    
    this.clear();
    if(this.text.alpha <= 0) {
      //this.text.destroy();
      return this.destroy();
    }
    let fontSizeBasedOnAmount = this.fontSize * ((this.amount / 10) + 1);  
    if(fontSizeBasedOnAmount > 20) {
      fontSizeBasedOnAmount = 20
    }   
    this.text.setFontSize(this.text.fontSize = fontSizeBasedOnAmount);
    this.text.setAlpha(this.text.alpha - (1 * dt/1000)); 
    this.text.y -= .75;
  }
}