import React from 'react';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Gui from '../views';

function App() {

  return (    
    <Gui></Gui>
  );
}

export default App;
